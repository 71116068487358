import { render, staticRenderFns } from "./myPromotion.vue?vue&type=template&id=6e8c7b66&scoped=true&"
import script from "./myPromotion.vue?vue&type=script&lang=js&"
export * from "./myPromotion.vue?vue&type=script&lang=js&"
import style0 from "./myPromotion.vue?vue&type=style&index=0&id=6e8c7b66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8c7b66",
  null
  
)

export default component.exports