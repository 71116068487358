<template>
  <div class="my_promotion">
    <div class="page left_page">
      <div class="title">我的客户</div>

      <div class="search">
        <el-input
          placeholder="请输入用户昵称"
          v-model="firstForm.nickname"
          @change="getListData"
        >
          <template slot="prepend">
            <img
              class="icon_sousuo"
              src="https://www.zizhihelp.com/upload/static/web/mine_sousuo.png"
              alt=""
            />
          </template>
        </el-input>
      </div>

      <div class="list_wrapper">
        <div class="list scrollbar" v-if="list.length > 0" v-loading="loading1">
          <div
            :class="[
              'list_item',
              'cursorP',
              index === activeIndex ? 'active' : '',
            ]"
            v-for="(item, index) in list"
            @click="selectClient(index, item)"
          >
            <img
              class="avatar"
              :src="
                item.headimgurl
                  ? item.headimgurl
                  : 'https://www.zizhihelp.com/upload/static/web/mine_touxiang.png'
              "
              alt=""
            />

            <div class="info">
              <div class="name">{{ item.nickname }}</div>

              <div class="time_wrapper">
                <div class="num">邀请：{{ item.num }}人</div>
                <div class="time">最近登录：{{ item.loginTime }}</div>
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          v-if="list.length > 0"
          class="pagination"
          layout="prev, pager, next"
          :total="firstTotal"
          @current-change="handleFirstCurrentChange"
          :current-page="firstForm.pageNum"
          :page-size="firstForm.pageSize"
        >
        </el-pagination>

        <el-empty
          image="https://www.zizhihelp.com/upload/static/web/empty_nodata.png"
          description="暂无客户"
          v-if="list.length == 0"
        ></el-empty>
      </div>
    </div>
    <div class="page right_page">
      <div v-if="activeIndex !== null">
        <div class="title">
          <span class="fwb">{{ nextForm.nickname }}</span> 的客户
        </div>

        <div class="search">
          <el-input
            placeholder="请输入用户昵称"
            v-model="secondForm.nickname"
            @change="getSecondListData"
          >
            <template slot="prepend">
              <img
                class="icon_sousuo"
                src="https://www.zizhihelp.com/upload/static/web/mine_sousuo.png"
                alt=""
              />
            </template>
          </el-input>
        </div>

        <div class="list_wrapper">
          <div
            class="list scrollbar"
            v-if="secondList.length > 0"
            v-loading="loading2"
          >
            <div class="list_item" v-for="(item, index) in secondList">
              <img
                class="avatar"
                :src="
                  item.headimgurl
                    ? item.headimgurl
                    : 'https://www.zizhihelp.com/upload/static/web/mine_touxiang.png'
                "
                alt=""
              />
              <div class="info">
                <div class="name">{{ item.nickname }}</div>

                <div class="time_wrapper">
                  <div class="time">最近登录：{{ item.loginTime }}</div>
                </div>
              </div>
            </div>
          </div>

          <el-pagination
            v-if="secondList.length > 0"
            class="pagination"
            layout="prev, pager, next"
            :total="secondTotal"
            @current-change="handleSecondCurrentChange"
            :current-page="secondForm.pageNum"
            :page-size="secondForm.pageSize"
          >
          </el-pagination>

          <el-empty
            image="https://www.zizhihelp.com/upload/static/web/empty_nodata.png"
            description="暂无客户"
            v-if="secondList.length == 0"
          ></el-empty>
        </div>
      </div>

      <el-empty
        image="https://www.zizhihelp.com/upload/static/web/empty_wait.png"
        description="请选择左侧累积客户数据"
        v-else
      ></el-empty>
    </div>
  </div>
</template>

<script>
/* 个人中心-推广中心 */
import { getTotalClient, getNextClientList } from "@/api/personalCenter.js";

export default {
  data() {
    return {
      name: "",
      firstForm: {
        nickname: "",
        pageNum: 1,
        pageSize: 10,
      },
      firstTotal: 0,
      list: [],
      activeIndex: null,
      secondForm: {
        nextUserId: "",
        nickname: "",
        pageNum: 1,
        pageSize: 10,
      },
      secondTotal: 0,
      secondList: [],
      nextForm: {},
      loading1: false,
      loading2: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取累积客户数据
    async getListData() {
      this.loading1 = true;

      let { code, data } = await getTotalClient(this.firstForm);
      if (code === 0) {
        this.list = data.records;
        this.firstTotal = data.total;
        this.loading1 = false;
      }
    },
    // 获取累积客户数据
    async getSecondListData() {
      this.loading2 = true;
      const params = {
        ...this.secondForm,
        nextUserId: this.nextForm.nextUserId,
      };

      let { code, data } = await getNextClientList(params);
      if (code === 0) {
        this.secondList = data.records;
        this.secondTotal = data.total;
        this.loading2 = false;
      }
    },

    /* 操作 */
    // 选择累积客户
    selectClient(index, item) {
      this.activeIndex = index;
      this.nextForm = { ...item };
      this.getSecondListData();
    },
    // 分页-我的客户
    handleFirstCurrentChange(val) {
      this.firstForm.pageNum = val;
      this.getListData();
    },
    // 分页-我的客户的下级客户
    handleSecondCurrentChange(val) {
      this.secondForm.pageNum = val;
      this.getSecondListData();
    },

    /* 初始化 */
    __init__() {
      this.getListData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.my_promotion {
  padding: 40px;
  display: flex;

  .page {
    flex: 1;
    padding: 22px 30px;
    box-shadow: 0px 0px 6px 0px rgba(157, 195, 221, 0.5);
    border-radius: 8px;
  }

  .left_page {
    margin-right: 40px;
  }

  .left_page,
  .page {
    .title {
      color: #333;
      font-size: 18px;
      margin-bottom: 16px;
    }

    /deep/.el-input__inner {
      border-radius: 0px 8px 8px 0px !important;
      border-left: none;
      border-color: #eaeaea;
      height: 48px;
      padding-left: 0px;
    }

    /deep/ .el-input-group__prepend {
      background-color: #fff !important;
      border-radius: 8px 0px 0px 8px !important;
      border-color: #eaeaea;
      padding: 0px 16px;

      .icon_sousuo {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }

    /deep/.el-empty {
      padding: 150px 0px;
    }

    .list {
      height: 500px;
      overflow-y: scroll;
      margin-top: 20px;

      .list_item {
        display: flex;
        align-items: center;
        padding: 20px 16px;
        border-radius: 8px;
        background-color: #f9fafc;
        margin-bottom: 12px;

        .avatar {
          width: 42px;
          height: 42px;
          margin-right: 10px;
          border-radius: 50%;
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;

          .name {
            color: #333;
            font-size: 14px;
            margin-bottom: 10px;
          }

          .time_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            .num,
            .time {
              display: flex;
              align-items: center;
              color: #666;
            }
          }
        }
      }

      .active {
        border: 1px solid #4f7bff;
      }
    }
  }
}
</style>
